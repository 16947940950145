import * as React from 'react'
import { useServiceMetaData } from '../../components/hooks/schema/servicesSchema'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import CallOut from '../../components/callOut'
import SplashTwo from '../../components/splashTwo'

const MoldRepairPage = () => {
  const data = useServiceMetaData()

  const ServiceSchema = ` 
    {
      "@context": "https://schema.org/",
      "@type": "Service",
      "serviceType": "mold damage restoration services",
	  "alternateName": "mold damage repair services",
      "provider": {
        "@type": "LocalBusiness",
        "name": "${data.title}",
        "image": "${data.siteUrl}${data.siteLogo}",
        "address": "${data.businessAddress}",
        "telephone": "${data.businessNumber}",
        "priceRange": "$$ - $$$$"
      },
      "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": ${data.geoLon}
                "longitude": ${data.geoLat}
            },
            "geoRadius": ${data.geoRad}
        },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "mold damage restoration services",
        "itemListElement": [
          {
            "@type": "OfferCatalog",
            "name": "mold damage restoration services",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "mold damage restoration services"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "mold damage repair services"
                }
              }
            ]
          }
        ]
      }
    }
  `

  return (
    <Layout>
      <Seo
        title="Mold Restoration Services"
        description="Don't let mold eat away at your home, this includes wood structures, flooring and your personal property."
        canonical="https://ecotechrestoration.ca/services/mold-damage-repair-and-restoration/"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: ServiceSchema }}
      />
      {/* Heros */}

      <div className="heroWrapper">
        <StaticImage
          className="heroImage"
          src="../../images/heros/mold.jpg"
          quality={95}
          alt="Mold Restoration Services"
        />
        <div className="overlay" />
        <h1 className="services__heading heroText">
          Expert Mold Inspection,<br />Remediation, &amp; Removal Services
        </h1>
      </div>

      <SiteWidthWrapper>
        <CallOut>
          Do you have a Mold Damage Removal or Restoration Emergency?
        </CallOut>

        <h2>
          Comprehensive Mold Solutions for a Healthy Living Environment
        </h2>
        <p>
          Our mold removal professionals offer a full range of services, including mold inspection, mold remediation, and mold control, to ensure your home or business remains mold-free.
        </p>

        <h2>
          Certified Mold Inspection Near You
        </h2>
          <p>
            Our mold inspection services meticulously assess your property, examining visible and hidden areas for any signs of mold growth. Using advanced mold testing tools and mold treatment techniques, our experienced mold inspectors ensure accurate results to detect even the slightest traces of mold. Early detection is crucial in preventing mold-related health risks, such as allergies, respiratory issues, and other complications.
          </p>
          <p>
            In addition to visual inspection, we collect air and surface samples for mold testing. This helps us identify the specific mold species present and determine the severity of the infestation. Armed with this information, our team of experts can formulate an effective mold remediation plan tailored to your circumstances to protect your property and safeguard the health of its occupants.
          </p>
          <p>
            By choosing our mold inspection services, you can trust that your home or business is in good hands. We prioritize your safety and well-being while providing practical solutions for preventing future mold growth.
          </p>

        <h2>
          Effective Mold Remediation for Lasting Results
        </h2>
        <p>
          Our mold remediation process begins with our technicians thoroughly evaluating the affected area to develop. We then develop a tailored plan that will effectively address the mold issue. We then use professional-grade mold cleaner and remover products to eliminate mold growth. This ensures that visible mold and any hidden spores that can continue to cause problems are removed.
        </p>
        <p>
          In addition to mold removal, our team takes measures to address the underlying causes of mold growth, such as excess moisture or humidity. We carefully assess and remediate any water damage and implement proper ventilation and moisture control systems as needed.
        </p>
        <p>
          We provide mold abatement services to prevent future mold occurrences, including sealing potential moisture entry points and applying mold-resistant coatings on surfaces. We also offer expert guidance on maintaining a healthy indoor environment and minimizing the risk of mold growth.
        </p>
        <p>
          Our comprehensive mold remediation process prioritizes your health and safety, delivering lasting results and peace of mind for your home or business.
        </p>

        <h2>
          Specialized Black Mold Removal Services
        </h2>
        <p>
          We understand the severe health risks associated with the most dangerous types of mold: black mold, also known as Stachybotrys chartarum. Our team of highly trained and experienced professionals is well-equipped to safely and effectively handle black mold removal.
        </p>
        <p>
          We naturally take extra precautions to protect your safety and prevent cross-contamination. Our team follows strict industry guidelines, utilizing personal protective equipment (PPE) and containment measures such as sealing off affected areas with plastic barriers.
        </p>
        <p>
          We employ advanced mold removal techniques, including HEPA vacuuming and air filtration, to capture and remove black mold spores from the air and surfaces. Once the black mold is safely removed, we thoroughly clean and disinfect the affected area, ensuring all traces of black mold and its toxic byproducts are removed.
        </p>

        <h2>
          Attic Mold Inspection and Removal
        </h2>
        <p>
          Mold in attic spaces can cause significant damage to your property, compromising the structural integrity and air quality of your surroundings. Unfortunately, attic mold often goes unnoticed, thriving in hard-to-reach areas with high humidity, poor ventilation, or roof leaks. Our specialized attic mold removal services are designed to identify and eliminate mold growth in these challenging, often overlooked spaces.
        </p>

        <h2>
          Water Damage Mold Treatment and Repair
        </h2>
        <p>
        Water damage, whether caused by leaks, flooding, or excessive humidity, create the perfect environment for mold growth in your home or business. Therefore, it is crucial to address water damage promptly to prevent mold infestation and the associated health risks. Our restoration company also has specialized teams dedicated to effectively handling water damage. This guarantees that a speedy and expert restoration of your home.
        </p>

        <h2>
          Trustworthy Mold Removal Contractors in Your Area
        </h2>
        <p>Our mold removal contractors and specialists near you are committed to exceptional service. We are a mold-tech-certified team that delivers top-quality mold cleaning and control solutions.
        Contact Ecotech Restorations today for professional mold repair services because we're the best at cleaning mold. Let us help you maintain a healthy living environment in your home or business.
        </p>




        <p className="services__alertP">
          For more information on how mold affects your health, check out the
          CDC’s webpage on Mold.
        </p>
        <SplashTwo title="Mold Damage Repair" />
      </SiteWidthWrapper>
    </Layout>
  )
}

export default MoldRepairPage
